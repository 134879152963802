import React from 'react'
import { makeStyles } from '@mui/styles'
import Modal from '@mui/material/Modal'
import FilterListIcon from '@mui/icons-material/FilterList'

import { useCategoriesData } from '../../lib/products/categories_hook'
import { useColorsData } from '../../lib/products/colors_hook'
import FilterSelect from './FilterSelect'

import { Category, Color, Ratio, Artist, Style } from '../../types'

import { Badge } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import Button from '@mui/material/Button'
import { useRatiosData } from '../../lib/products/ratios_hook'
import { useArtistsData } from '../../lib/artists_hook'
import { useStylesData } from '../../lib/products/styles_hook'
import { useTranslation } from 'react-i18next'

function getModalStyle() {
  return {
    margin: 'auto',
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const except = (allValues: any, except: string) => {
  let keys = Object.keys(allValues)

  let result: string[] = []

  keys
    .filter((key: string) => {
      return key != except
    })
    .map((key: string) => {
      result = result.concat(allValues[key])
    })

  return result
}

export default function FilterModal(props: any) {
  const categoriesData: Category[] = useCategoriesData()
  const colorsData: Color[] = useColorsData()
  let ratiosData: Ratio[] = useRatiosData()
  const artistsData: Artist[] = useArtistsData()
  const stylesData: Style[] = useStylesData()

  if (props.filterModal.onlyRatios.length > 0) {
    ratiosData = ratiosData.filter((ratio: any) => {
      return props.filterModal.onlyRatios.includes(ratio.slug)
    })
  }

  const { t } = useTranslation('translation')
  let template = props.filterModal.template

  let allValues = {
    categories: props.filterModal.categories,
    colors: props.filterModal.colors,
    ratios: props.filterModal.ratios,
    artists: props.filterModal.artists,
    styles: props.filterModal.styles,
  }

  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleApply = () => {
    setOpen(false)
  }

  const badgeCount =
    allValues.categories.length +
    allValues.colors.length +
    allValues.ratios.length +
    allValues.artists.length +
    allValues.styles.length

  let showRatios = true
  if (
    props.filterModal.hide !== undefined &&
    props.filterModal.hide.indexOf('ratios') >= 0 &&
    allValues.ratios.length == 0
  ) {
    showRatios = false
  }

  return (
    <div>
      <Badge badgeContent={badgeCount} color="primary">
        <Button variant="contained" color="primary" onClick={handleOpen}>
          <FilterListIcon /> Filter
        </Button>
      </Badge>
      <Modal
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="filter-modal-title">{t('Motivauswahl')}</h2>
          <p id="simple-modal-description">
            {t('Auf der Suche nach dem perfekten Motiv? Null Problemo.')}
          </p>
          {/* <p>
            Du suchst z.B. nach Bildern mit Lamas? Gib ins Suchfeld "Lama" ein.
            Dann zeigen wir dir unter dem Suchfeld schon mal ein paar unserer
            Top-Motive. Falls dir das noch nicht reicht, klicke einfach auf
            "Suchen". Und voilà: Wir präsentieren dir aaaaaaall unsere Bilder
            mit Lamas. Los, probier's mal aus! PS: Funktioniert nicht nur mit
            Lamas.
          </p> */}

          {/* <div className="row">
            <div className="col d-flex justify-content-center">
              <SearchField isOnProductsPage={true} showSubmitButton={false} />
            </div>
          </div> */}

          <div className="row">
            <div className="col d-flex justify-content-center">
              <FilterSelect
                name="FilterStyles"
                allValues={stylesData}
                template={template}
                selectedValues={allValues.styles}
                otherValues={except(allValues, 'styles')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col d-flex justify-content-center">
              <FilterSelect
                name="FilterCategories"
                allValues={categoriesData}
                template={template}
                selectedValues={allValues.categories}
                otherValues={except(allValues, 'categories')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col d-flex justify-content-center">
              <FilterSelect
                name="FilterColors"
                allValues={colorsData}
                template={template}
                selectedValues={allValues.colors}
                otherValues={except(allValues, 'colors')}
              />
            </div>
          </div>

          {showRatios && (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <FilterSelect
                  name="FilterRatios"
                  allValues={ratiosData}
                  template={template}
                  selectedValues={allValues.ratios}
                  otherValues={except(allValues, 'ratios')}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col d-flex justify-content-center">
              <FilterSelect
                name="FilterArtists"
                allValues={artistsData}
                template={template}
                selectedValues={allValues.artists}
                otherValues={except(allValues, 'artists')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col d-flex justify-content-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleApply}
                style={{ marginTop: `24px` }}
              >
                <CloseIcon /> {t('Schließen')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
