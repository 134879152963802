import React from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import ProductsOverview from './ProductsOverview'
import Pagination from '../generic/Pagination'
import SortingOrderButton from './SortingOrderButton'

import { PaginationProps, Artist, AlternateUrl } from '../../types'
import { useProductsData } from '../../lib/products_hooks'

import { LoadMoreThanOneProductOptions } from '../../types'

import FilterModal from './FilterModal'

import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import {
  getProductsTemplateUrl_Page,
  getProductsTemplateUrl_SortingOrder,
  getProductsTemplateUrl_Categories,
  getProductsTemplateUrl,
} from '../../utils/utils'
import MaterialTeaser from '../cms/MaterialTeaser'
import CmsBlock from '../cms/CmsBlock'
import { useArtistsData } from '../../lib/artists_hook'
import {env} from '../../../environment'
import { buildAlternateUrlsForPues } from '../../utils/UrlUtils'
import SEO from '../generic/Seo'
import {
  setEnvironmentVariable,
  pushTmEvent,
} from '../layout/EnvironmentVariables'
import { trackNonInteractionEvent } from '../../utils/ArtboxoneTracking'
import { getHash } from '../../utils/Crypto'
import { LinearProgress } from '@mui/material'

interface ProductsPageProps {
  materialName: string
  page: number
  categories: string[]
  colors: string[]
  ratios: string[]
  artists: string[]
  styles: string[]
  sortingOrder: string
  searchQuery: string
  variant?: string
}

const ProductsPage: React.FC<ProductsPageProps> = ({
  materialName,
  page,
  categories,
  colors,
  ratios,
  artists,
  styles,
  sortingOrder,
  searchQuery,
  variant,
}) => {
  const materialTeaserData = useStaticQuery(graphql`
    query {
      allCockpitMaterialTeaser {
        nodes {
          cockpitId
          material_key {
            value
          }
          meta_title {
            value
          }
          meta_description {
            value
          }
          teaser {
            value {
              childMarkdownRemark {
                html
              }
            }
          }
          teaser_more {
            value {
              childMarkdownRemark {
                html
              }
            }
          }
          lang
        }
      }
    }
  `)

  const { t } = useTranslation('translation')

  const optionsCount: LoadMoreThanOneProductOptions = {
    material: materialName,
    count: true,
    searchQuery: searchQuery,
    categories: categories,
    colors: colors,
    ratios: ratios,
    artists: artists,
    sortingOrder: sortingOrder,
    styles: styles,
  }

  let rising = false
  if (materialName == 'poster') {
    // rising = true
  }

  let productCount = 6
  let pageCount: number | undefined = undefined

  const productsCount: any = useProductsData(optionsCount)

  // Reminder mbo - Add all filtered elements in this array:
  let urlfilters = categories
    .concat(colors)
    .concat(ratios)
    .concat(artists)
    .concat(styles)
    .sort()

  let teaserBlockName = ''

  if (urlfilters.length >= 1) {
    teaserBlockName = 'teaser_' + materialName + '_' + urlfilters.join('_')
  }

  let teaserDataArray = materialTeaserData.allCockpitMaterialTeaser.nodes
    .filter((obj: any) => {
      return (
        obj.lang === env.getCmsLanguage() &&
        (obj.material_key.value === materialName ||
          obj.material_key.value === teaserBlockName)
      )
    })
    .sort((a: any, b: any) => {
      return a.material_key.value.length > b.material_key.value.length ? -1 : 1
    })

  let teaserData = undefined
  if (teaserDataArray.length > 0) {
    teaserData = teaserDataArray[0]
  }

  let teaserIsMaterialteaser = teaserDataArray.length == 1

  let templateUrl = getProductsTemplateUrl_Page(
    materialName,
    urlfilters,
    sortingOrder,
    searchQuery,
    variant,
  )

  if (typeof productsCount['count'] != 'undefined') {
    productCount = productsCount['count']
    pageCount = Math.ceil(productsCount['count'] / 60)

    if (page > pageCount && pageCount >= 1) {
      console.log('Select page is larger than available pages -> Redirect')
      navigate(templateUrl.replace('%d', '' + pageCount))
    }
  }

  let paginationProps: PaginationProps = {
    template: templateUrl,
    currentPage: page,
    pageCount: pageCount,
  }

  let signature = getHash({
    material: materialName,
    page: page,
    searchQuery: searchQuery,
    categories: categories,
    colors: colors,
    artists: artists,
    styles: styles,
    productCount: productCount,
    sortingOrder: sortingOrder,
  })

  const className = page === 1 ? 'first-page' : 'additional-page'

  let sortingOrderProps = {
    template: getProductsTemplateUrl_SortingOrder(
      materialName,
      urlfilters,
      page,
      searchQuery,
      variant,
    ),
    sortingOrder: sortingOrder,
  }

  let searchRelevance = searchQuery != ''

  let filterModalProps: any = {
    template: getProductsTemplateUrl_Categories(
      materialName,
      sortingOrder,
      page,
      searchQuery,
      variant,
    ),
    categories: categories,
    colors: colors,
    ratios: ratios,
    artists: artists,
    styles: styles,
    hide: [],
    onlyRatios: [],
  }

  if (
    [
      'cases',
      'cups',
      'magnet',
      'placemat',
      'tshirt',
      'sweatshirt',
      'hoodie',
    ].indexOf(materialName) >= 0 ||
    variant == 'ueei'
  ) {
    filterModalProps.hide.push('ratios')
  }

  if (['wallcalendar'].indexOf(materialName) >= 0) {
    filterModalProps.onlyRatios.push('querformat')
    filterModalProps.onlyRatios.push('hochformat')
  } else if (
    [
      'foldingcalendar',
      'deskcalendar',
      'squarecalendar',
      'familyplaner',
    ].indexOf(materialName) >= 0 ||
    variant == 'ueei'
  ) {
    filterModalProps.hide.push('ratios')
  }

  if (['advent', 'puzzle'].includes(materialName)) {
    filterModalProps.onlyRatios.push('querformat')
    if (variant != 'ueei') {
      filterModalProps.onlyRatios.push('hochformat')
    }
  } else if (materialName == 'poster_plastic') {
    filterModalProps.onlyRatios.push('hochformat')
    filterModalProps.onlyRatios.push('querformat')
    filterModalProps.onlyRatios.push('quadratisch')
  } else if (materialName == 'metalposter') {
    filterModalProps.onlyRatios.push('hochformat')
    filterModalProps.onlyRatios.push('querformat')
  }

  let robots = 'noindex, follow'

  if (env.getSeo() == '1') {
    robots =
      page == 1 && searchQuery === '' ? 'index, follow' : 'noindex, follow'
  }

  // Canoncial
  let canonicals: any = []

  canonicals.push(
    <link
      key="canonical-pues"
      rel="canonical"
      href={
        env.getBaseUrl() +
        getProductsTemplateUrl(
          materialName,
          urlfilters,
          sortingOrder,
          searchQuery,
          1,
          variant,
        )
      }
    />,
  )

  if (page == 1) {
    if (env.getSeo() == '1') {
      buildAlternateUrlsForPues(
        materialName,
        urlfilters,
        sortingOrder,
        searchQuery,
        page,
        variant,
      ).map((alternateUrl: AlternateUrl) => {
        canonicals.push(
          <link
            rel="alternate"
            key={'alternate-' + alternateUrl.url}
            hrefLang={alternateUrl.locale}
            href={alternateUrl.url}
          />,
        )
      })
    }
  }

  let i18nMaterialName = t(materialName)
  let title = i18nMaterialName

  let cmsBlockId = undefined
  if (artists.length == 1) {
    cmsBlockId = 'artist_' + artists[0]

    let artistsData: Artist[] = useArtistsData().filter((artist: Artist) => {
      return artists[0] == artist.slug
    })

    if (artistsData.length == 1) {
      title += ' ' + t('von') + ' ' + artistsData[0].name
    }
  }

  let showFilter = true

  if (productCount < 9) {
    showFilter = false
  }

  if (urlfilters.length > 0) {
    showFilter = true
  }

  let seoTitleBase = t('%t online kaufen bei der artboxONE')
  let seoDescriptionBase = t(
    '%t kaufst du am besten bei der artboxONE. Große Auswahl an Bildern, Fotographien und Kunstdrucken.',
  )

  let seoTitle = seoTitleBase.replace('%t', i18nMaterialName)
  let seoDescription = seoDescriptionBase.replace('%t', i18nMaterialName)

  if (teaserIsMaterialteaser && urlfilters.length == 1) {
    seoTitle = seoTitleBase.replace(
      '%t',
      urlfilters.join(', ').toUpperCase() + ' ' + i18nMaterialName,
    )

    seoDescription = seoDescriptionBase.replace(
      '%t',
      urlfilters.join(', ').toUpperCase() + ' ' + i18nMaterialName,
    )
  } else if (teaserData !== undefined) {
    if (teaserData.meta_description.value) {
      seoDescription = teaserData.meta_description.value
    }

    if (teaserData.meta_title && teaserData.meta_title.value) {
      seoTitle = teaserData.meta_title.value
    }
  }

  let seo = <SEO title={seoTitle} description={seoDescription} />

  let outOfStock = false
  let outOfStockInfo = <></>

  // if (materialName == 'advent' && ['ueei'].indexOf(variant) >= 0) {
  //   outOfStockInfo = (
  //     <div className="row">
  //       <div className="col">
  //         <Alert
  //           key="out_of_stock"
  //           variant="info"
  //           style={{ textAlign: `center` }}
  //         >
  //           <p>
  //             {t(
  //               'Leider ist die gewählte Variante bereits ausverkauft. Bitte schaue doch, ob dir auch die Adventskalender mit anderen Füllungen gefallen.',
  //             )}
  //           </p>
  //           <CTAButton
  //             context="PUES"
  //             title={t('Alle Adventskalender anzeigen')}
  //             link="/adventskalender"
  //           />
  //         </Alert>
  //       </div>
  //     </div>
  //   )

  //   outOfStock = true
  // }

  if (searchQuery != '') {
    trackNonInteractionEvent({
      category: 'OnSiteSearch',
      action: 'Pues',
      label: searchQuery,
    })
  }

  let finishedLoadingPage = typeof productsCount['count'] != 'undefined'

  return (
    <>
      <Helmet>
        <meta name="robots" content={robots} />
        {canonicals.map((canonical: any) => canonical)}
      </Helmet>
      {seo}
      {setEnvironmentVariable('pageType', 'pues')}
      {pushTmEvent('artboxone-pues-loaded')}

      <div className="container">
        <div className="row">
          <div className={'col teaser ' + className}>
            <h1>{title}</h1>
            {searchQuery ? (
              <h2>
                {t('Suche nach')} "{searchQuery}"
              </h2>
            ) : (
              ''
            )}
            <div
              className={'col teasertext ' + className}
              data-test="materialteasertext"
            >
              <MaterialTeaser
                teaserData={teaserData}
                teaserBlockName={teaserBlockName}
              />

              {cmsBlockId && (
                <CmsBlock
                  showButton={t('Mehr zum Künstler')}
                  blockId={cmsBlockId}
                />
              )}
            </div>
          </div>
        </div>

        {outOfStock == true && outOfStockInfo}

        <div className="row">
          <div className="col d-flex justify-content-center justify-content-md-start padding-bottom">
            {showFilter && <FilterModal filterModal={filterModalProps} />}
          </div>
          <div className="col d-flex justify-content-center padding-bottom">
            {productCount > 1 && (
              <SortingOrderButton
                sortingOrder={sortingOrderProps}
                searchRelevance={searchRelevance}
                rising={rising}
              />
            )}
          </div>
          <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
            <Pagination pagination={paginationProps} />
          </div>
        </div>
        {finishedLoadingPage ? (
          <ProductsOverview
            key={signature}
            material={materialName}
            page={page}
            searchQuery={searchQuery}
            categories={categories}
            colors={colors}
            ratios={ratios}
            artists={artists}
            styles={styles}
            productCount={productCount}
            sortingOrder={sortingOrder}
            variant={variant}
          />
        ) : (
          <LinearProgress />
        )}

        <div className="row">
          <div className="col d-flex justify-content-center justify-content-md-end padding-bottom">
            <Pagination pagination={paginationProps} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductsPage
