import React from 'react'
import SelectButton from '../generic/SelectButton'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

export default function SortingOrderButton(props: any) {
  let selectedKey = props.sortingOrder.sortingOrder
  let templateUrl = props.sortingOrder.template

  const { t } = useTranslation('translation')

  let options = [
    { key: 'bestseller', name: t('bestseller') },
    { key: 'new-in', name: t('new-in') },
  ]

  if (props.rising == true) {
    options.push({ key: 'rising-stars', name: t('rising-stars') })
  }

  if (props.searchRelevance == true) {
    options.push({ key: 'relevance', name: t('relevance') })
  }

  const buttonName = 'CategorySortingOrder'

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SelectButton
          options={options}
          name={buttonName}
          selectedKey={selectedKey}
          templateUrl={templateUrl}
          replace={false}
        />
      </Grid>
    </Grid>
  )
}
